import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en/translation.json";
import translationES from "./locales/es/translation.json";
import translationDE from "./locales/de/translation.json";
import LanguageDetector from "i18next-browser-languagedetector";

// the translations
const resources = {
  en: { translation: translationEN },
  es: { translation: translationES },
  de: { translation: translationDE },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources: resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

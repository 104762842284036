import axios from "axios";
const baseUrl = `${process.env.GATSBY_BACKEND_API_URL}/api`;

export function getTrips() {
  return axios.get(`${baseUrl}/trips`);
}

export function getSlots(tripId) {
  return axios.get(`${baseUrl}/trips/${tripId}/slots`);
}

export function getExtras(tripId) {
  return axios.get(`${baseUrl}/trips/${tripId}/extras`);
}

export function bookTrip(booking) {
  return axios.post(`${baseUrl}/bookings/book`, booking);
}

export function searchTrips(
  start,
  end,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday
) {
  let url = `${baseUrl}/trips/search?start=${start}&end=${end}`;

  url = monday ? url + `&monday=true` : url;
  url = tuesday ? url + `&tuesday=true` : url;
  url = wednesday ? url + `&wednesday=true` : url;
  url = thursday ? url + `&thursday=true` : url;
  url = friday ? url + `&friday=true` : url;
  url = saturday ? url + `&saturday=true` : url;
  url = sunday ? url + `&sunday=true` : url;

  return axios.get(url);
}

import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const SearchContext = createContext("");

const SearchContextProvider = ({ children }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [mondaySelected, setMondaySelected] = useState(false);
  const [tuesdaySelected, setTuesdaySelected] = useState(false);
  const [wednesdaySelected, setWednesdaySelected] = useState(false);
  const [thursdaySelected, setThursdaySelected] = useState(false);
  const [fridaySelected, setFridaySelected] = useState(false);
  const [saturdaySelected, setSaturdaySelected] = useState(false);
  const [sundaySelected, setSundaySelected] = useState(false);

  const clearContext = () => {
    setStartDate(null);
    setEndDate(null);
    setMondaySelected(false);
    setTuesdaySelected(false);
    setWednesdaySelected(false);
    setThursdaySelected(false);
    setFridaySelected(false);
    setSaturdaySelected(false);
    setSundaySelected(false);
  };

  return (
    <SearchContext.Provider
      value={{
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        mondaySelected,
        tuesdaySelected,
        wednesdaySelected,
        thursdaySelected,
        fridaySelected,
        saturdaySelected,
        sundaySelected,
        setMondaySelected,
        setTuesdaySelected,
        setWednesdaySelected,
        setThursdaySelected,
        setFridaySelected,
        setSaturdaySelected,
        setSundaySelected,
        clearContext,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

SearchContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SearchContextProvider;

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-affiliates-js": () => import("./../../../src/pages/affiliates.js" /* webpackChunkName: "component---src-pages-affiliates-js" */),
  "component---src-pages-cala-sant-vicenc-js": () => import("./../../../src/pages/cala-sant-vicenc.js" /* webpackChunkName: "component---src-pages-cala-sant-vicenc-js" */),
  "component---src-pages-contract-conditions-js": () => import("./../../../src/pages/contract-conditions.js" /* webpackChunkName: "component---src-pages-contract-conditions-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-dolphins-js": () => import("./../../../src/pages/dolphins.js" /* webpackChunkName: "component---src-pages-dolphins-js" */),
  "component---src-pages-formentor-beach-js": () => import("./../../../src/pages/formentor-beach.js" /* webpackChunkName: "component---src-pages-formentor-beach-js" */),
  "component---src-pages-formentor-point-js": () => import("./../../../src/pages/formentor-point.js" /* webpackChunkName: "component---src-pages-formentor-point-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-notice-js": () => import("./../../../src/pages/legal-notice.js" /* webpackChunkName: "component---src-pages-legal-notice-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-pollensa-bay-js": () => import("./../../../src/pages/pollensa-bay.js" /* webpackChunkName: "component---src-pages-pollensa-bay-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-search-results-js": () => import("./../../../src/pages/search-results.js" /* webpackChunkName: "component---src-pages-search-results-js" */)
}


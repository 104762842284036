import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./src/assets/css/materialdesignicons.min.css";
import "./src/assets/scss/theme.scss";
import "./src/i18n";

import React from "react";
import BookingContextProvider from "./src/components/ContextProviders/BookingContextProvider";
import SearchContextProvider from "./src/components/ContextProviders/SearchContextProvider";

export const wrapRootElement = ({ element }) => (
  <BookingContextProvider>
    <SearchContextProvider>{element}</SearchContextProvider>
  </BookingContextProvider>
);

export const onRenderBody = ({ setHeadComponents }) => {
  setHeadComponents([
    <link
      rel="preload"
      href="/fonts/1Ptug8zYS_SKggPNyC0ITw.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
      key="1Ptug8zYS_SKggPNyC0ITw"
    />,
    <link
      rel="preload"
      href="/fonts/1Ptug8zYS_SKggPNyCAIT5lu.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
      key="1Ptug8zYS_SKggPNyCAIT5lu"
    />,
    <link
      rel="preload"
      href="/fonts/1Ptug8zYS_SKggPNyCIIT5lu.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
      key="1Ptug8zYS_SKggPNyCIIT5lu"
    />,
    <link
      rel="preload"
      href="/fonts/1Ptug8zYS_SKggPNyCkIT5lu.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
      key="1Ptug8zYS_SKggPNyCkIT5lu"
    />,
    <link
      rel="preload"
      href="/fonts/1Ptug8zYS_SKggPNyCMIT5lu.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
      key="1Ptug8zYS_SKggPNyCMIT5lu"
    />,
    <link
      rel="preload"
      href="/fonts/iJWKBXyIfDnIV7nBrXw.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
      key="iJWKBXyIfDnIV7nBrXw"
    />,
    <link
      rel="preload"
      href="/fonts/iJWKBXyIfDnIV7nDrXyi0A.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
      key="iJWKBXyIfDnIV7nDrXyi0A"
    />,
    <link
      rel="preload"
      href="/fonts/iJWKBXyIfDnIV7nErXyi0A.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
      key="iJWKBXyIfDnIV7nErXyi0A"
    />,
    <link
      rel="preload"
      href="/fonts/iJWKBXyIfDnIV7nFrXyi0A.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
      key="iJWKBXyIfDnIV7nFrXyi0A"
    />,
    <link
      rel="preload"
      href="/fonts/iJWKBXyIfDnIV7nMrXyi0A.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
      key="iJWKBXyIfDnIV7nMrXyi0A"
    />,
    <link
      rel="preload"
      href="/fonts/iJWKBXyIfDnIV7nPrXyi0A.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
      key="iJWKBXyIfDnIV7nPrXyi0A"
    />,
  ]);
};
